@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
    url("./assets/fonts/Gotham-Medium.otf") format("opentype");
}

* {
  font-family: "Gotham";
  color: #454545;
}

button {
  background-color: rgba(0, 0, 0, 0);
  border: none;
}
